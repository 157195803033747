import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout, Footer } from '@legalshield/adonis-ux-framework';
import { FormsList } from './components';
import './App.css';
import { ScrollToTop } from './hooks/ScrollToTop';
import { FirebaseContainer } from './context/FirebaseContainer';

export const App = () => {
  return (
    <FirebaseContainer>
      <Layout classNames={['lsux-layout-padding']} variant="none">
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<FormsList />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ScrollToTop>
        <Footer align="right" classNames={['lsux-layout-padding']} htmlContent={footerHtml} />
      </Layout>
    </FirebaseContainer>
  );
};
