import React, { useState } from 'react';
import { Container, Grid, GridCol, GridRow } from '@legalshield/adonis-ux-framework';
import { FormInfo, isGroupBenefitsDomain } from '../../utils/util';
import { AuthModal, EnrollmentCard, FormsLibrary, LegalDisclaimer, PdfImage } from '../../components';
import { useFirebase } from '../../context/FirebaseContext';

const FormsList = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<FormInfo>();
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const firebase = useFirebase();

  const isAuthenticated = authNPayload != null && authNPayloadValid;

  const displayModals = () => {
    if (isAuthenticated || isGroupBenefitsDomain()) {
      setModal(true);
      setShowAuthModal(false);
    } else {
      setShowAuthModal(true);
    }
  };

  ads_set_title(string_table.TITLE);

  const handleFormClick = (item: FormInfo) => {
    let formName = item.name;
    if (item.state) {
      formName = formName.concat(` - ${item.state}`);
    }
    firebase.fireEvent('click_form', { name: formName });
    setSelectedForm(item);
    displayModals();
  };

  return (
    <>
      <Grid>
        <GridRow variant="eight-four">
          <GridCol classNames={['column1']}>
            <FormsLibrary handleClick={handleFormClick} />
          </GridCol>
          <GridCol classNames={['column2']} style={{ paddingTop: '28px' }}>
            <>
              {isGroupBenefitsDomain() && (
                <Container classNames={['my-4']}>
                  <EnrollmentCard />
                </Container>
              )}
            </>
            <Container classNames={['my-4']}>
              <LegalDisclaimer />
            </Container>
          </GridCol>
        </GridRow>
      </Grid>

      <>
        {modal && selectedForm && <PdfImage form={selectedForm} setModal={setModal} />}
        {showAuthModal && <AuthModal closeFunction={() => setShowAuthModal(false)} />}
      </>
    </>
  );
};

export { FormsList };
