import React from 'react';
import { Heading } from '@legalshield/adonis-ux-framework';
import './EnrollmentCard.scss';

export const EnrollmentCard = () => {
  return (
    <div id="Enrollment_Card">
      <Heading as="T26" text={string_table.ENROLLMENT_CARD_TITLE} />
      <div id="Enrollment_Container">
        <div id="Enrollment_Content">
          <div className="Enrollment_Description">{string_table.ENROLLMENT_CARD_CONTENT1}</div>
          <div className="Enrollment_Description">{string_table.ENROLLMENT_CARD_CONTENT2}</div>
        </div>
      </div>
    </div>
  );
};
