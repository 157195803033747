import React, { createContext } from 'react';

export const FirebaseContext = createContext<{
  fireEvent: (eventName: string, eventParams?: { [p: string]: any } | undefined) => void;
} | null>(null);

export const useFirebase = () => {
  const context = React.useContext(FirebaseContext);

  if (context === undefined || context == null) {
    throw new Error('useApp must be used within a AppContainer');
  }
  return context;
};
