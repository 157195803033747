// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #787878;
  overflow: scroll;
  z-index: 1000;
}

.sticky-download {
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  bottom: 0;
  margin-bottom: 60px;
  z-index: 9999
}

.pageNavigation {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 40px;
  z-index: 9999;
}

.closeButton {
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: -40px;
}


.pageCount {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  width: fit-content;
  padding: 18px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  z-index: 1006;
}

@media screen and (max-width: 640px) {
  .pageCount {
    height: 35px;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,kBAAkB;EAClB,iBAAiB;AACnB;;;AAGA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: #787878;\n  overflow: scroll;\n  z-index: 1000;\n}\n\n.sticky-download {\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  bottom: 0;\n  margin-bottom: 60px;\n  z-index: 9999\n}\n\n.pageNavigation {\n  position: absolute;\n  top: 0;\n  left: 50%;\n  margin-top: 40px;\n  z-index: 9999;\n}\n\n.closeButton {\n  position: absolute;\n  right: 0;\n  margin-right: 20px;\n  margin-top: -40px;\n}\n\n\n.pageCount {\n  display: flex;\n  height: 40px;\n  justify-content: center;\n  align-items: center;\n  background-color: #eeeeee;\n  width: fit-content;\n  padding: 18px;\n  margin-left: 10px;\n  margin-right: 10px;\n  border-radius: 5px;\n  z-index: 1006;\n}\n\n@media screen and (max-width: 640px) {\n  .pageCount {\n    height: 35px;\n  }\n}\n\n.react-pdf__Page__canvas {\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
