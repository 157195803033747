import React, { useEffect, useState } from 'react';
import { Container, Select, Spinner, Table, TableCell, TableRow } from '@legalshield/adonis-ux-framework';
import { getFormsLibrary, titleCase, FormInfo } from '../../utils/util';
import { stateAndProvinceAbbreviationsToNames } from '../../utils/values';

export const FormsLibrary = ({ handleClick }: { handleClick: (formName: FormInfo) => void }) => {
  const [currentState, setCurrentState] = useState<string>('Other');
  const [currentCategory, setCurrentCategory] = useState<string>('*');
  const [library, setLibrary] = useState<Map<string, Map<string, FormInfo[]>>>(new Map());

  // prettier-ignore
  const areaOfLawLabels: Record<string, string> = {
    "Business": string_table.BUSINESS,
    "Civil Litigation": string_table.CIVIL_LITIGATION,
    "Consumer Rights": string_table.CONSUMER_RIGHTS,
    "Contract": string_table.CONTRACT,
    "Criminal": string_table.CRIMINAL,
    "Employment": string_table.EMPLOYMENT,
    "Estate Planning": string_table.ESTATE_PLANNING,
    "Family Law": string_table.FAMILY_LAW,
    "General": string_table.GENERAL,
    "Landlord_Tenant": string_table.LANDLORD_TENANT,
    "Other Law": string_table.OTHER_LAW,
    "Real Estate": string_table.REAL_ESTATE,
    "Traffic": string_table.TRAFFIC
  }

  useEffect(() => {
    const fetchLibrary = async () => {
      const forms = await getFormsLibrary();
      setLibrary(forms);
    };
    fetchLibrary();
  }, []);

  function compare(a: FormInfo, b: FormInfo) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const stateOptions = Array.from(library.keys()).sort();
  const currentInfo = library.get(currentState) || new Map();
  const categoryOptions = Array.from(currentInfo.keys()).sort();
  const tableData = library.get(currentState)?.get(currentCategory)?.sort(compare);
  const stateDropdown = [
    { label: string_table.ANY_LOCATION, value: 'Other' },
    Array.from(stateOptions)
      .filter((a) => a != 'Other')
      .map((state) => ({ label: stateAndProvinceAbbreviationsToNames[state] || state, value: state })),
  ].flat();

  const categoryDropdown = [
    { label: string_table.ANY_AOL, value: '*' },
    Array.from(categoryOptions)
      .filter((a) => a != '*')
      .map((category: string) => ({ label: areaOfLawLabels[category] || category, value: category })),
  ].flat();

  const handleFormClick = (formName: FormInfo) => {
    handleClick(formName);
  };

  return (
    <>
      {tableData == undefined ? (
        <Container flexbox={true} justifyContent="center" alignItems="center" classNames={['py-3']}>
          <Spinner spinnerSize="xlarge" color="brand" />
        </Container>
      ) : (
        <>
          <div style={{ marginBottom: '20px' }}>
            <h2>{string_table.LOCATION_FILTER}</h2>
            <Select
              options={stateDropdown}
              value={currentState}
              onValueChange={(e: string) => {
                setCurrentState(e);
                setCurrentCategory('*');
              }}
            />
            <h2>{string_table.AOL_FILTER}</h2>
            <Select
              options={categoryDropdown}
              value={currentCategory}
              onValueChange={(e: string) => setCurrentCategory(e)}
            />
          </div>
          <Table noBorder>
            <TableRow isHeader columns="50% 25% 25%">
              <TableCell>{string_table.PDF_NAME}</TableCell>
              <TableCell>{string_table.STATE_PROVINCE}</TableCell>
              <TableCell>{string_table.AOL_COLUMN}</TableCell>
            </TableRow>

            {tableData !== undefined ? (
              tableData.map((item, index) => {
                return (
                  <TableRow
                    isOdd={index % 2 != 0}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleFormClick(item)}
                    key={index}
                    columns="50% 25% 25%"
                  >
                    <TableCell>{titleCase(item.name).replace('.pdf', '')}</TableCell>
                    <TableCell>
                      <>{stateAndProvinceAbbreviationsToNames[currentState] || currentState}</>
                    </TableCell>
                    <TableCell>{areaOfLawLabels[item.aol] || item.aol}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <></>
            )}
          </Table>
        </>
      )}
    </>
  );
};
