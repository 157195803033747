import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import '../../App.css';
import { Button, Container, Spinner } from '@legalshield/adonis-ux-framework';
import { useFirebase } from '../../context/FirebaseContext';
import { FormInfo } from '../../utils/util';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PdfImageProps = {
  form: FormInfo;
  setModal: (T: boolean) => void;
};

const PdfImage = (props: PdfImageProps) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show first page
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [scale, setScale] = useState(1.5);
  const [documentMarginLeft, setDocumentMarginLeft] = useState(0);
  const [documentMarginTop, setDocumentMarginTop] = useState(120);
  const ref = useRef<HTMLDivElement>(null);
  const { form, setModal } = props;
  const firebase = useFirebase();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 640);
    // Small/Medium mobile
    if (window.innerWidth > 320 && window.innerWidth <= 375) {
      setScale(0.9);
    }
    // Large mobile
    else if (window.innerWidth > 375 && window.innerWidth <= 768) {
      setScale(1.1);
    } else {
      setScale(1.5);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useOnClickOutside(ref, () => setModal(false));

  function onScaleChangeZoomIn() {
    setScale(scale + 0.2);
    setDocumentMarginLeft(documentMarginLeft + 54);
    setDocumentMarginTop(documentMarginTop - 4);
  }

  function onScaleChangeZoomOut() {
    setScale(scale - 0.2);
    setDocumentMarginLeft(documentMarginLeft - 54);
    setDocumentMarginTop(documentMarginTop + 4);
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const downloadClick = () => {
    let formName = form.name;
    if (form.state) {
      formName = formName.concat(` - ${form.state}`);
    }
    firebase.fireEvent('download_form', { name: formName });
  };

  // Shrinks the page nav controls ever so slightly for mobile devices so it takes up less real estate
  const pageNavStyle = isMobile ? { width: '30px', height: '30px', marginTop: '3px' } : {};

  return (
    <Container background="none" classNames={['overlay']}>
      <Container
        flexbox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        classNames={['pt-4']}
        background="none"
        ref={ref}
      >
        {/* Sticky Download Button */}
        <div className="sticky-download">
          <a href={form.downloadUrl} download={form.name} style={{ textDecoration: 'none' }}>
            {isMobile ? (
              <Button label={string_table.DOWNLOAD} iconRight="action_download" onClick={downloadClick} />
            ) : (
              <Button stretch widthLong="300px" label={string_table.DOWNLOAD} onClick={downloadClick} />
            )}
          </a>
        </div>

        <Container
          classNames={['pb-4']}
          flexbox
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'space-between'}
          background="none"
        >
          {/* Close Preview Button */}
          <Button
            iconLeft="action_close"
            variant="primary"
            onClick={() => {
              setModal(false);
            }}
            classNames={['mr-4']}
            style={{
              position: 'absolute',
              right: 0,
            }}
          />

          <>
            {/* Page Navigation Controls */}
            <Container flexbox background="none">
              <Container background="none">
                <Button onClick={previousPage} disabled={pageNumber <= 1} iconLeft="nav_arrow_left" style={pageNavStyle} />
              </Container>
              <Container background="none">
                <span className="pageCount">
                  <p style={{ fontSize: 16 }}>
                    {string_table.PAGE} {pageNumber || (numPages ? 1 : '--')} {string_table.OF} {numPages || '--'}
                  </p>
                </span>
              </Container>
              <Container background="none">
                <Button
                  onClick={nextPage}
                  disabled={pageNumber >= numPages}
                  iconLeft="nav_arrow_right"
                  style={pageNavStyle}
                />
              </Container>
            </Container>
          </>
          <>
            {/* Zoom Controls (Desktop Only) */}
            {!isMobile && (
              <Container classNames={['ml-6']} flexbox background="none">
                <Container flexbox background="none">
                  <Button onClick={onScaleChangeZoomOut} iconLeft="action_minus" />
                </Container>
                <Container flexbox classNames={['pl-2']} background="none">
                  <Button onClick={onScaleChangeZoomIn} iconLeft="action_add" />
                </Container>
              </Container>
            )}
          </>
        </Container>

        {/* PDF Display */}
        <Container flexbox background="none" flexDirection="column">
          <div className="pdfRow">
            <div style={{ zIndex: 2 }}>
              <Document
                file={form.downloadUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => (
                  <div
                    style={{
                      width: 300,
                      height: 200,
                      backgroundColor: 'white',
                      marginTop: isMobile ? 50 : 200,
                    }}
                  >
                    <p style={{ fontSize: 18, color: '#480076' }}>Error loading file. Please try again later.</p>
                  </div>
                )}
                loading={
                  <div style={{ marginTop: isMobile ? 50 : 200 }}>
                    <Spinner spinnerSize="xlarge" color="brand" />
                  </div>
                }
              >
                <Page pageNumber={pageNumber} height={isMobile ? 450 : 625} scale={scale} />
              </Document>
            </div>
          </div>
        </Container>
      </Container>
    </Container>
  );
};

type Handler = (event: Event) => any;

// Hook
function useOnClickOutside(ref: React.RefObject<HTMLDivElement>, handler: Handler) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export { PdfImage };
