import React, { useState } from 'react';
import { Modal, Text, Link, Button } from '@legalshield/adonis-ux-framework';
import { Login } from '@legalshield/frontend-commons';

type AuthModalProps = {
  closeFunction: () => void;
};

export const AuthModal = ({ closeFunction }: AuthModalProps) => {
  const [showModal, setShowModal] = useState(true);

  const handleOk = () => {
    Login.redirectToSignUp();
  };

  const handleClose = () => {
    setShowModal(false);
    closeFunction();
  };

  return (
    <>
      {showModal && (
        <Modal closeButton={true} closeFunction={handleClose}>
          <Modal.Title>{string_table.ANONYMOUS_MODAL_TITLE}</Modal.Title>
          <Text>
            <>
              {string_table.ANONYMOUS_MODAL_CONTENT}
              <Link decoration={true} href={Login.getLoginUrl()} text={string_table.SIGN_IN} />.
            </>
          </Text>
          <Modal.Actions>
            <Button label={string_table.ANONYMOUS_MODAL_OKAY} onClick={handleOk} />
            <Button label="Cancel" variant="secondary" onClick={handleClose} />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default AuthModal;
