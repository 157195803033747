import React from 'react';
import { Button, Heading } from '@legalshield/adonis-ux-framework';
import { isGroupBenefitsDomain, rootDomain } from '../../utils/util';
import { useFirebase } from '../../context/FirebaseContext';
import './LegalDisclaimer.scss';

export const LegalDisclaimer = () => {
  const firebase = useFirebase();

  const handleClick = () => {
    firebase.fireEvent('request_consult');
    window.location.href = `https://legal.${rootDomain()}/request-consult?category=contracts`;
  };

  let legalDisclaimerText = string_table.DISCLAIMER_CONTENT2;
  if (!isGroupBenefitsDomain()) {
    legalDisclaimerText += ` ${string_table.DISCLAIMER_CONTENT3}`;
  }

  return (
    <div id="Disclaimer_Card">
      <Heading as="T26" text={string_table.DISCLAIMER_TITLE} />
      <div id="Disclaimer_Container">
        <div id="Disclaimer_Content">
          <div className="Disclaimer_Description">{string_table.DISCLAIMER_CONTENT1}</div>
          <div className="Disclaimer_Description">{legalDisclaimerText}</div>
          {!isGroupBenefitsDomain() && (
            <Button label={string_table.REQUEST_CONSULTATION} stretch widthLong="100%" onClick={handleClick} />
          )}
        </div>
      </div>
    </div>
  );
};
