export const isGroupBenefitsDomain = () => {
  return rootDomain().includes('shieldbenefits.com');
};

export const rootDomain = () => {
  return window.location.hostname.split('.').splice(-2).join('.');
};

export function isDev() {
  return rootDomain().includes('dev-');
}

export function isUat() {
  return rootDomain().includes('uat-');
}
export interface FormInfo {
  name: string;
  state: string;
  aol: string;
  downloadUrl: string;
}

export const getFormsLibrary = async () => {
  const result = await fetch('/v2/forms');
  const data = await result.json();

  // Function to convert a nested object into a Map
  const objectToMap = (obj: any) => {
    const map = new Map();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // Recursively convert nested objects to Maps as well
        map.set(key, objectToMap(value));
      } else {
        map.set(key, value);
      }
    });
    return map;
  };

  // Convert the top-level object and return
  return objectToMap(data);
};

export function titleCase(str: string) {
  const lowerCase = str.toLowerCase().split(' ');
  for (let i = 0; i < lowerCase.length; i++) {
    lowerCase[i] = lowerCase[i].charAt(0).toUpperCase() + lowerCase[i].slice(1);
  }
  return lowerCase.join(' ');
}

export function stringToHash(str: string) {
  let hash = 0;

  if (str.length == 0) return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}
