import React, { ReactNode } from 'react';
import { fireEvent } from '../firebase';
import { FirebaseContext } from './FirebaseContext';

export const FirebaseContainer = ({ children }: { children: ReactNode }) => {
  const value = {
    fireEvent,
  };
  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};
